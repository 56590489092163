import { useState } from 'react';
import college_logo from '../../assets/images/college_log.svg';
import { Button, MegaMenu, Navbar } from 'flowbite-react';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
interface subItem {
  caption: string;
  url: string;
}
interface ISubMenus {
  title: string;
  menuItems: subItem[];
  keyNum?: number;
  curKey?: number;
  isCurLink?: boolean;
  setIsOpened?: (val: number) => void;
  isDesktopMod?: boolean;
  selected?: string;
  onAfterLink?: () => void;
}
export interface ILinkProps {
  title: string;
  link: string;
  selected: string;
  isCurOpen?: boolean;
  goLink?: () => void;
}
export const Header = () => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectItem, setSelectItem] = useState(0);
  const curLinkCss =
    'text-[#3FC6AD] font-bold underline underline-offset-[6px] decoration-4';
  const mobile_bg =
    pathname == '/' ? 'max-md:bg-[#2B574F]' : 'max-md:bg-[#2B574F]';
  const commonLinkCss = 'text-[#2B2B2B]';
  const HeroArea = () => {
    return (
      <div className="flex items-end justify-center h-full space-y-2">
        <div className="p-5 space-y-4 hero_caption_sm w-[90vw]">
          <div className="flex items-center space-x-4">
            <div>
              <img src={college_logo} alt="" />
            </div>
            <h6 className="text-xl font-extrabold text-white">
              MyCollegeCosts
            </h6>
          </div>

          <h6 className="text-[#fff] text-center font-opensans font-normal text-wrap">
            A tool to help students receive the best possible financial aid
            award
          </h6>
        </div>
      </div>
    );
  };
  const LinkItem = ({
    title,
    link,
    selected,
    isCurOpen,
    goLink,
  }: ILinkProps) => {
    const bSelected =
      (selected === link || (link === '/Home' && selected === '/')) &&
      isCurOpen;
    return (
      <Link
        to={link}
        onClick={() => setIsOpen(false)}
        className={` ${pathname == link ? curLinkCss : commonLinkCss} `}
      >
        {title}
      </Link>
    );
  };
  const SunMbMenuItem = ({
    title,
    menuItems,
    keyNum,
    curKey,
    setIsOpened,
    onAfterLink,
  }: ISubMenus) => {
    const isOpen = keyNum === curKey;
    return (
      <div>
        <div
          className={`text-[#2B2B2B] menu_p_item_wrap md:h-[71px] ${
            isOpen && 'bg-menu_blue'
          }`}
          onClick={() => {
            if (keyNum && setIsOpened) {
              setIsOpened(keyNum);
            }
          }}
        >
          {title}{' '}
          <span className="text-[#3FC6AD]">
            <DropdownTrigger />
          </span>
        </div>

        {!!isOpen && (
          <div className="px-5 py-5 list-none border-t border-b border-[#2B574F]">
            {menuItems.map((item, index) => (
              <Link
                key={index}
                to={item.url}
                onClick={() => onAfterLink && onAfterLink()}
              >
                <li
                  className={` ${
                    pathname == item.url ? curLinkCss : commonLinkCss
                  } py-2`}
                >
                  {item.caption}
                </li>
              </Link>
            ))}
          </div>
        )}
      </div>
    );
  };
  const DropdownTrigger = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
      >
        <path
          d="M12.1897 16.2131L18.1997 10.2031L16.7867 8.78809L12.1897 13.3881L7.59369 8.78809L6.17969 10.2021L12.1897 16.2131Z"
          fill="white"
        />
      </svg>
    );
  };
  const subMenuItems: subItem[] = [
    { caption: 'Contact Us', url: '/contact' },
    // { caption: "Team", url: "/team" },
    { caption: 'Privacy Policy', url: '/privacypolicy' },
  ];

  return (
    <header
      className={`${mobile_bg} md:sticky md:top-0 md:z-30 header_shadow font-opensans `}
    >
      <nav className="flex md:justify-center xl:justify-between py-2 max-md:hidden">
        <div className="flex px-8 max-xl:hidden">
          <img src={college_logo} alt="" />
          <div className="flex items-end px-2">
            <h6 className="text-[#2B574F] font-extrabold xl:text-xl font-opensans">
              MyCollegeCosts
            </h6>
          </div>
        </div>
        <div className="flex items-center justify-center py-2 font-opensans px-8 xl:space-x-[40px] md:space-x-5 max-xl:text-sm">
          <Link
            to={'/'}
            className={` ${pathname === '/' ? curLinkCss : commonLinkCss} `}
          >
            Home
          </Link>
          <Link
            to={'/students_parents_guardians'}
            className={`  ${
              pathname === '/students_parents_guardians'
                ? curLinkCss
                : commonLinkCss
            } `}
          >
            Students/Parents
          </Link>

          <Link
            to={'/foundations'}
            className={` ${
              pathname === '/foundations' ? curLinkCss : commonLinkCss
            } `}
          >
            Foundations
          </Link>
          <Link
            to={'/about_us'}
            className={` ${
              pathname === '/about_us' ? curLinkCss : commonLinkCss
            } `}
          >
            About Us
          </Link>
          <div>
            <MegaMenu.Dropdown
              toggle={<>Contact</>}
              theme={{
                base: '',
                toggle: {
                  arrowIcon: 'ml-3 h-4 w-4',
                  content: 'py-1 focus:outline-none',
                  floating: {
                    animation: 'transition-opacity',
                    arrow: {
                      base: 'absolute z-10 h-2 w-2 rotate-45',
                      style: {
                        dark: 'bg-gray-900 dark:bg-gray-700',
                        light: 'bg-white',
                        auto: 'bg-white dark:bg-gray-700',
                      },
                      placement: '-4px',
                    },
                    base: 'z-10 w-fit divide-y divide-gray-100 rounded shadow focus:outline-none mt-2 block',
                    content: 'py-1 text-sm text-gray-500 dark:text-gray-400',
                    divider: 'my-1 h-px bg-gray-100 dark:bg-gray-600',
                    header:
                      'block px-4 py-2 text-sm text-gray-700 dark:text-gray-200',
                    hidden: 'invisible opacity-0',
                    item: {
                      container: '',
                      base: 'flex w-full cursor-pointer items-center justify-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:bg-gray-600 dark:focus:text-white',
                      icon: 'mr-2 h-4 w-4',
                    },
                    style: {
                      dark: 'bg-gray-900 text-white dark:bg-gray-700',
                      light: 'border border-gray-200 bg-white text-gray-900',
                      auto: 'border border-gray-200 bg-white dark:border-none dark:bg-gray-700 text-gray-500 dark:text-gray-400',
                    },
                    target: 'w-fit',
                  },
                  inlineWrapper: 'flex w-full items-center justify-between',
                },
              }}
            >
              <div className="space-y-4 p-4 bg-[#]">
                <li>
                  <Link
                    to={'/contact'}
                    className={`${
                      pathname == '/contact' ? curLinkCss : commonLinkCss
                    } `}
                  >
                    Contact Form
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to={"/team"}
                    className={`${
                      pathname == "/team" ? curLinkCss : commonLinkCss
                    } `}
                  >
                    Team
                  </Link>
                </li> */}
                <li>
                  <Link
                    to={'/privacypolicy'}
                    className={`${
                      pathname == '/privacypolicy' ? curLinkCss : commonLinkCss
                    } `}
                  >
                    Privacy Policy
                  </Link>
                </li>
              </div>
            </MegaMenu.Dropdown>
          </div>
          <div>
            {/* <button className="text-white bg-[#3FC6AD] font-extrabold md:px-4 xl:px-8 py-3 rounded-lg">
              <NavLink to={"/contact"}>
                <span>Contact Us</span>
              </NavLink>
            </button> */}
          </div>
        </div>
      </nav>
      <nav className="md:hidden h-[307px] ">
        <div className="absolute z-20 top-3 left-3">
          <button
            type="button"
            className={
              isOpen
                ? 'block text-[#2B574F] focus:outline-none'
                : 'block text-white focus:outline-none'
            }
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
              {isOpen ? (
                <path
                  fill-rule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              ) : (
                <path
                  fill-rule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              )}
            </svg>
          </button>
        </div>
        {isOpen && (
          <div className="bg-white text-white w-8/12 grid place-content-start gap-y-8 absolute pt-[48px] h-lvh ps-3 pb-8 z-10">
            <div className="flex ps-3">
              <img src={college_logo} alt="" />
              <div className="flex items-end px-2">
                <h6
                  className="font-extrabold text-[#2B574F] xl:text-xl font-opensans"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  MyCollegeCosts
                </h6>
              </div>
            </div>
            <LinkItem
              title="Home"
              link="/"
              selected={pathname}
              isCurOpen={selectItem === 0}
            />
            <LinkItem
              title="Students/Parents"
              link="/students_parents_guardians"
              selected={pathname}
              isCurOpen={selectItem === 0}
            />
            <LinkItem
              title="Foundations"
              link="/foundations"
              selected={pathname}
              isCurOpen={selectItem === 0}
            />

            <SunMbMenuItem
              title="Contact"
              menuItems={subMenuItems}
              curKey={selectItem}
              isCurLink={
                subMenuItems.findIndex((it) => it.url === pathname) > -1
              }
              keyNum={1}
              onAfterLink={() => setIsOpen(false)}
              setIsOpened={(num) => setSelectItem(num)}
            />
          </div>
        )}
        <HeroArea />
      </nav>
    </header>
  );
};
